import React from 'react'
import styled, { css } from 'styled-components';


const mainText = css`
  color: ${props => props.color || props.theme.colors.primary};
  /* letter-spacing : -3px; */
`;


const Subtitle = styled.p`
  ${mainText}
  font-size: 7rem;
  font-weight: 300; 

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
  }
`

const Title = styled.h1`
  
  ${mainText}

  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  font-size: 12rem;
  visibility: ${props => props.show ? "visible" : "hidden"};
  z-index: 15;
  user-select: none;

  @media ${props => props.theme.breaks.down('lg')} {
    top: 45%;
    font-size: 10rem;
    transform: translateY(-50%);
  }

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
    top: 35%;
    left: 10%;
    width: 60%;

    &.section-1-subtitle9 {
      top: 60%;
    }

    &.section-1-subtitle10 {
      top: 35%;
      left: 10%;
    }

    &.section-1-subtitle12 {
      left: 10%;
      top: 55%;
      width: 85%;
    }
  }

  @media ${props => props.theme.breaks.down('smp')} {
    width: 85%;

    &.section-1-subtitle9 {
      top: 50%;
    }
  }
`

const Light = styled.span`
  font-weight: 300;
  color: inherit;
  font-size: inherit;
`

 
export default ({title, subtitle, light, className, id, click, show, color}) => {

 
 return (
 <>
  <Title show={show} id={id} className={className} 
  color={color} onClick={click}>
    <Subtitle className="h3" color={color}>
      {subtitle}
    </Subtitle> 
    {title} {light ? <Light>{light}</Light> : null}
  </Title>
 </>
 )
}