import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/src/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);


const master = gsap.timeline();

const startSlide = () => {

  const counterEl = document.getElementById('counter');

  

  let counter = {count: 0};
  const tl = gsap.timeline();

  const updateNum = () => {
    counterEl.innerHTML = counter.count
  }

  tl.set(".loader", {xPercent: -94, yPercent: 100})
  .set('.section-1', {xPercent: -100})
  .set('#header', {autoAlpha: 0})

  // start the loading bar
  .addLabel('load')
  .to('.loader', {yPercent: 0, duration: 2, ease: "ease.out"}, "load") // load the bar

  // update the loading percentage
  .to(counter, {count: "+=100", duration: 2, roundProps: 'count', onUpdate: updateNum}, "load")
  .to('#counter', {y: 150, duration: 1, ease: 'ease.out'}, "load+=1")

  // start panel swiping animation
  .to('.loader', {xPercent: 100, duration: .5,}, 'load+=2.7')
  .to('.section-1', {xPercent: 0, duration: .5}, 'load+=3.1')
  .to('#header', {autoAlpha: 1, duration: .4}, "load+=3.2")

  return tl;
}

export default () => {
  master.add(startSlide());
  // scrollers();
}

   