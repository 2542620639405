import React from 'react'
import styled from 'styled-components';

import RowWrapper from "../../../utils/rowWrapper";

import TitleSection from "./TitleSection.js";
import Subsection from './subsection';

import { theme } from "../../../utils/theme"
import SectionFooter from '../section-footer/SectionFooter';


const SectionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;  
  z-index: 5;

  @media ${props => props.theme.breaks.down('lg')} {
    .section-1-subtitle4 {
      font-size: 10rem;
      top: 45%;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    .section-1-subtitle4 {
      font-size: 10rem;
      word-break: break-all;
      width: 230px;
      left: 30%;
      top: 50%;
      visibility: hidden;

      p {
        position: absolute;
        font-size: 7rem;
        transform: rotateZ(-90deg);
        left: -60%;
        top: 30%;
      }
    }
  }
`

const Section = styled.section`
  height: 100vh;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: hidden;
  overflow-x:hidden;
`

const SubsectionContainer = styled.div`
  position: relative;
  height: 100vh;
`

const FixedFooter = styled.div`
  width: 50rem;
  position: fixed;
  padding-left: 40px;
  bottom: 0;
  left: 0;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`

export default ({className}) => {

  
  return (
    <SectionContainer className="section-2-container">
      <Section className={className}>
        <SubsectionContainer>
          <TitleSection id="section-1-subtitle4" className="section-1-subtitle4" title="Branding." subtitle="This is" color={theme.colors.primary} sec="1" sub="4" show />

          <Subsection imageClass="sec-1-img-5 can-see" id="section-1-subtitle5" video="https://res.cloudinary.com/dx5iiskeu/video/upload/v1618211667/things_ixdsir.mp4" videoOg="https://res.cloudinary.com/celebrand/video/upload/v1621979241/Things_you_can_see_yt5ag0.ogv" className="section-1-subtitle5" title="You can" subtitle="It's things" light="see." color={theme.colors.body} sec="1" sub="5" />

          <Subsection id="section-1-subtitle6" className="section-1-subtitle6" title="You can't" subtitle="It's things" light="see." color={theme.colors.body} sec="1" sub="6" ex="ex-6" ext="46%" exl="40px" extm="55%" exlm="75%" link="/cant-see" bg={theme.colors.body} col={theme.colors.dark} />

          <Subsection imageClass="sec-1-img-7 stand-out" id="section-1-subtitle7" image="https://res.cloudinary.com/celebrand/image/upload/v1618279963/stand-out_sgyhmm.png" className="section-1-subtitle7" title="stand out." subtitle="It's how you" color={theme.colors.body} sec="1" sub="7" />

          <Subsection imageClass="sec-1-img-8 get-ahead" id="section-1-subtitle8" image="https://res.cloudinary.com/celebrand/image/upload/v1619113237/get-ahead_le2k2u.png" className="section-1-subtitle8" title="get ahead." subtitle="It's how you" color={theme.colors.body} sec="1" sub="8" />

          <Subsection imageClass="sec-1-img-9 your-story" id="section-1-subtitle9" image="https://res.cloudinary.com/celebrand/image/upload/v1618279964/your-story_fdazzq.jpg" className="section-1-subtitle9" title={<>you tell <br /> your story.</>}  subtitle="It's how" color={theme.colors.body} sec="1" sub="9" ex="ex-9" ext="80%" exl="75%" extm="70%" exlm="70%" link="/portfolio" bg={theme.colors.body} col={theme.colors.dark} />
          
          <Subsection imageClass="sec-1-img-10 listening" id="section-1-subtitle10" image="https://res.cloudinary.com/celebrand/image/upload/v1618279960/listening_ofnzzl.jpg" className="section-1-subtitle10" title={<>listening <br /> to you.</>}  subtitle={<>it's how <br /> people <br /> start</>} color={theme.colors.body} sec="1" sub="10" />

          <FixedFooter>
            <SectionFooter />
          </FixedFooter>

        </SubsectionContainer>
      </Section>
    </SectionContainer>
)
}

