import React from 'react'
import styled from 'styled-components'

const Subsection = styled.div`
  /* position: relative; */
  width: 100%;
  /* height: 80rem; */
`

const TitleBg = styled.div`
 position: absolute;
 right: 0;
 top: 0;
 height: 100vh;
 background-color: ${props => props.theme.colors.dark};
 width: 30%;
 visibility: hidden;
 z-index: 1000;

 @media ${props => props.theme.breaks.down('md')} {
  width: 100%;
  height: 70%;
  bottom: 0;
  top: auto;
 }
`

const Title = styled.p`
  width: 340px;
  position: absolute;
  right: 8%;
  top: 30%;
  text-transform: uppercase;
  font-size: 50px;
  visibility: hidden;
  color: ${props => props.theme.colors.primary};
  font-weight: 300;

  span {
    color: ${props => props.theme.colors.body};
    font-size: inherit;
  }

  @media ${props => props.theme.breaks.down('lg')} {
    font-size: 5rem;
    right: 2%;
    width: 280px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 70%;
    right: auto;
    left: 10%;
    top: 15%;
    font-size: 45px;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 4rem;
    width: 85%;
  }
`

const Image = styled.div`
  background-image: url(${props => props.src});
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  left: 0;
  top: 0;
  width: 70%;
  height: 105vh;
  background-position: center;
  background-size: cover;
  z-index: 100;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

export default ({image, imageClass, opac, id, className}) => {
 
 return (
 <Subsection>
  <TitleBg className="subtitle13-bg">
    <Title className={className} id={id}>It's the  feeling <br /> you get when  everybody <span>loves your business</span> as  much as you do.</Title>
  </TitleBg>
  {image ? <Image className={imageClass} src={image} opac={opac}></Image> : null}
 </Subsection>
 )
}