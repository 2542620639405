import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
// import { Link, graphql } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout";
import gsap from 'gsap';
import {theme} from '../utils/theme';
import { navigate } from "gatsby"

import Loader from "../components/animatedPanel/AnimatedPanel";
import Section1 from "../components/sections/section1/Section1";
import Section2 from "../components/sections/section2/Section2";
import Section3 from "../components/sections/section3/Section3";

import context from '../context';


import startSlide from "../utils/tween";

const Container = styled.div`
  overflow: hidden;
  overflow-x: hidden;
`

const Drag = styled.span`
  display: inline-block;
  position: fixed;
  top: 45%;
  transform: translateY(-50%);
  text-transform: uppercase;
  right: 10rem;
  color: rgba(0, 0, 0, .25);
  font-size: 1.3rem;
  z-index: 99;
  /* user-select: none; */

  @media ${props => props.theme.breaks.down('md')} {
    top: auto;
    bottom: 8rem;
    right: auto;
    left: 5rem;
  }
`

const IndexPage = (props) => {

  const location = props.location;


  const slides = ['#celebrand', '#couch', '#couches', '#creative', '#branding', '#can-see', '#cant-see', '#stand-out', '#get-ahead', '#story', '#listening', '#we-can', '#list', '#business', '#easy', '#whats-wrong', '#form']

  useEffect(() => {

    
    document.getElementById('nav_toggle').checked = false;
    
    if (context.effectRan) { 

      let drag = location.hash === "#branding" ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)';

      const tl = gsap.timeline();

      const commonCss = {fontSize: "2.5rem", fontWeight: '600', letterSpacing: '.5'}

      let h1StylesStart = {top: '50%', translateY: '-50%', fontSize: 120}
      let h3StylesStart = {fontSize: 70, fontWeight: 300}
      let h1StylesEnd = {top: 50, translateY: '50%', opacity: .95, ...commonCss}
      let h3StylesEnd = {...commonCss}

      let color;
      let switchUp;

      const screenWidth = window.innerWidth;

      if (screenWidth < 1280 && screenWidth > 960) {
        h1StylesStart = {top: '45%', translateY: '-50%', fontSize: "10rem"}
      }

      if (screenWidth < 960) {
        h1StylesStart = {top: '50%', fontSize: "10rem", left: '35%'};
        h1StylesEnd = {left: '10%', top: 100, opacity: .95, ...commonCss};
        h3StylesStart = {fontSize: "7rem", fontWeight: 300, rotate: -90, left: '-60%', top: '30%'};
        h3StylesEnd = {...commonCss, rotate: 0, top: -30, left: 0};

      }

      const curSlide = slides.indexOf(location.hash);


      if (curSlide >= 4 && curSlide < 11  && context.start) {  
        
        //branding


        const secArr = [];

        for (let i = 5; i < 17; i++) {
          secArr.push(`.section-1-subtitle${i}`, `.bu-sec-1-sub-${i}`)
        }

        secArr.push(`.can-see`, '.stand-out', '.get-ahead', '.your-story', '.listening', `.list-goes`, '.easy', '.wrong', '.sec-1-img-13', '.subtitle13-bg', '#dark-bg-14', '.main-form')

        // console.log(secArr);

        color = theme.colors.body;
        switchUp = theme.colors.dark;

        let contactColor = {color: color, borderColor: color}

        if (screenWidth < 960) {
          contactColor = {color: switchUp, backgroundColor: color}
        }

        tl.addLabel('begin-tl')
        .to('.loader', {autoAlpha: 0}, 'begin-tl')
        // .to('.section-3', {autoAlpha: 0}, 'begin-tl-=.1')
        .to('.section-2', {autoAlpha: 1}, 'begin-tl-=.2')
        .to('.section-2-container', {autoAlpha: 1}, 'begin-tl-=.2')
        .to(secArr, {autoAlpha: 0}, 'begin-tl+=.2')
        .to('.section-3-container', {autoAlpha: 0}, 'begin-tl-=.1')
        .to('.section-1-subtitle4', {autoAlpha: 1, color: theme.colors.primary, duration: .2}, 'begin-tl')
        .to('#header a', {color: color, duration: .2}, 'begin-tl')
        .to('.main-logo .st0', {fill: color, duration: .1}, 'begin-tl')
        .to('.nav_icon', {backgroundColor: color, duration: .1}, 'begin-tl')
        .to(`.get-touch`, {...contactColor, duration: .2}, 'begin-tl')
        .to(`.footer-icons`, {fill: color, duration: .2}, 'begin-tl')
        .to('.section-1', {autoAlpha: 0}, 'begin-tl')
        .to('.section-1-title', {...h1StylesEnd}, 'begin-tl')
        .to('.section-1-title .h3', {...h3StylesEnd}, 'begin-tl')
        .to('.drag', {autoAlpha: 1, color: drag, duration: 0}, 'begin-tl')

        if (screenWidth > 960) {
          tl.to('.section-1-subtitle10', {left: '55%'}, 'begin-tl')
        }

        if (curSlide === 4) {
        tl.to(['.section-1-subtitle4', '.section-1-subtitle11'], {...h1StylesStart, color: theme.colors.primary}, 'begin-tl')
        .to(['.section-1-subtitle4 .h3', '.section-1-subtitle11 .h3'], {...h3StylesStart, color: theme.colors.primary}, 'begin-tl')
        } else {
          tl.to('.section-1-subtitle4', {...h1StylesEnd, color: theme.colors.primary}, 'begin-tl')
          .to('.section-1-subtitle4 .h3', {...h3StylesEnd, color: theme.colors.primary}, 'begin-tl')
          .to([`.section-1-subtitle${curSlide}`, `.bu-sec-1-sub-${curSlide}`, `.ex-${curSlide}`, `.sec-1-img-${curSlide}`], {display: 'block', autoAlpha: 1}, 'begin-tl+=.4')
        }
        context.start = false;

      } else if (curSlide >= 11 && context.start) {  // what we can do

        const secArr = [];

        for (let i = 12; i < 16; i++) {
          if (i !== curSlide) {
          secArr.push(`.section-1-subtitle${i}`, `.bu-sec-1-sub-${i}`, `.sec-1-img-${i}`)
          }
        }

        secArr.push('.subtitle13-bg', '.main-form')

        if (curSlide !== 14) secArr.push('#dark-bg-14')

        color = theme.colors.dark;
        switchUp = theme.colors.body;


        let contactColor = {color: curSlide === 14 || curSlide === 15 ? switchUp : color, borderColor: curSlide === 14 || curSlide === 15 ? switchUp : color}

        if (screenWidth < 960) {
          contactColor = {color: switchUp, backgroundColor: color}
        }

        let sec3Col = {backgroundColor: curSlide === 15 ? theme.colors.dark : theme.colors.body}

        tl.addLabel('begin-tl')
        .to('.loader', {autoAlpha: 0}, 'begin-tl')
        .to(secArr, {autoAlpha: 0}, 'begin-tl-=.2')
        .to('.section-3', {autoAlpha: 1, ...sec3Col}, 'begin-tl-=.2')
        .to('.section-3-container', {autoAlpha: 1}, 'begin-tl-=.2')
        .to('#header a', {color: curSlide === 14 || curSlide === 15 ? switchUp : color, duration: .2}, 'begin-tl-=.2')
        .to('.main-logo .st0', {fill: curSlide === 15 ? switchUp : color, duration: .1}, 'begin-tl-=.2')
        .to('.nav_icon', {backgroundColor: curSlide === 15 ? switchUp : color, duration: .1}, 'begin-tl-=.2')
        .to(`.get-touch`, {...contactColor, duration: .2}, 'begin-tl-=.2')
        .to(`.footer-icons`, {fill: color, duration: .2}, 'begin-tl-=.2')
        .to('.section-1', {autoAlpha: 0}, 'begin-tl')
        .to('.section-1-subtitle4', {...h1StylesEnd}, 'begin-tl')
        .to('.section-1-subtitle4 .h3', {...h3StylesEnd}, 'begin-tl')
        .to('.section-1-title', {...h1StylesEnd}, 'begin-tl')
        .to('.section-1-title .h3', {...h3StylesEnd}, 'begin-tl')
        .to('.section-2', {autoAlpha: 1}, 'begin-tl')
        .to('.section-2-container', {autoAlpha: 1}, 'begin-tl')
        .to('.section-1-subtitle11', {autoAlpha: 1}, 'begin-tl')
        .to('.drag', {autoAlpha: 1, color: drag, duration: 0}, 'begin-tl')

        if (screenWidth > 960) {
          tl.to('.section-1-subtitle10', {left: '50%'}, 'begin-tl')
        }

        if (curSlide === 11) {
          tl.to('.section-1-subtitle11', {...h1StylesStart}, 'begin-tl')
          .to('.section-1-subtitle11 .h3', {...h3StylesStart, color: theme.colors.primary}, 'begin-tl')
        } else {
          tl.to('.section-1-subtitle11', {...h1StylesEnd}, 'begin-tl')
          .to('.section-1-subtitle11 .h3', {...h3StylesEnd, color: theme.colors.primary}, 'begin-tl')
          .to([`.section-1-subtitle${curSlide}`, `.bu-sec-1-sub-${curSlide}`, `.ex-${curSlide}`], {display: 'block', autoAlpha: 1}, 'begin-tl+=.2')
          .to(`.sec-1-img-${curSlide}`, {autoAlpha: 1}, 'begin-tl+=.2')

          if (curSlide === 14) {
            tl.to('#dark-bg-14', {autoAlpha: 1}, "begin-tl+=.2")
          }

          // if (curSlide === 15) {
          //   tl.to('.section-3', {backgroundColor: theme.colors.dark}, 'begin-tl+=.4')
          //   // .to('.sec-1-img-15', {autoAlpha: 0}, 'begin-tl+=.3')
          // }
        }


        context.start = false;

      } 
      
      else if (((curSlide >= 0 && curSlide < 4) || location.hash === "") && context.start) {

        // celebrand

        const secArr = [];

        for (let i = 1; i < 17; i++) {
          if (i !== curSlide) {
          secArr.push(`.section-1-subtitle${i}`, `.bu-sec-1-sub-${i}`, `.sec-1-img-${i}`)
        }
        }

        secArr.push(`.list-goes`, '.easy', '.wrong', '.sec-1-img-13', '.subtitle13-bg', '#dark-bg-14', '.main-form', `.can-see`, '.stand-out', '.get-ahead', '.your-story', '.listening')

        color = theme.colors.dark;
        switchUp = theme.colors.body;

        let contactColor = {color: color, borderColor: color}

        if (screenWidth < 960) {
          contactColor = {color: switchUp, backgroundColor: color}
        }

        tl.addLabel('begin-tl')
        .to('.loader', {autoAlpha: 0}, 'begin-tl')
        .to(secArr, {autoAlpha: 0}, 'begin-tl-=.2')
        .to('.section-1', {autoAlpha: 1}, 'begin-tl-=.2')
        .to('#header a', {color: color, duration: .2}, 'begin-tl-=.2')
        .to('.main-logo .st0', {fill: color, duration: .1}, 'begin-tl')
        .to('.nav_icon', {backgroundColor: color, duration: .1}, 'begin-tl')
        .to(`.get-touch`, {...contactColor, duration: .2}, 'begin-tl-=.2')
        .to(`.footer-icons`, {fill: color, duration: .2}, 'begin-tl-=.2')
        .to(['.section-1-subtitle11', '.section-1-subtitle4'], {...h1StylesStart, autoAlpha: 0}, 'begin-tl')
        .to(['.section-1-subtitle11 .h3', '.section-1-subtitle4 .h3'], {...h3StylesStart}, 'begin-tl')
        // .to('.section-3', {autoAlpha: 0}, 'begin-tl-=.1')
        .to('.section-3-container', {autoAlpha: 0}, 'begin-tl-=.1')
        // .to('.section-2', {autoAlpha: 0}, 'begin-tl-=.1')
        .to('.section-2-container', {autoAlpha: 0}, 'begin-tl-=.1')
        .to('.drag', {autoAlpha: 1, color: drag, duration: 0}, 'begin-tl')

        if (curSlide === 0 || location.hash === "") {
          tl.to('.section-1-title', {...h1StylesStart}, 'begin-tl')
          .to('.section-1-title .h3', {...h3StylesStart}, 'begin-tl')
        } else if (curSlide > 0) {
          tl.to('.section-1-title', {...h1StylesEnd}, 'begin-tl')
          .to('.section-1-title .h3', {...h3StylesEnd}, 'begin-tl')
          .to([`.section-1-subtitle${curSlide}`, `.bu-sec-1-sub-${curSlide}`, `.ex-${curSlide}`, `.sec-1-img-${curSlide}`], {display: 'block', autoAlpha: 1}, 'begin-tl+=.2')
        }

        context.start = false

      } else {
        context.start = false
        }
     
      return
    }

    if (location.hash !== ''){
      navigate('/')
      return
    }


    startSlide()
    context.effectRan = true
    context.start = false
  }, [location.hash])

  useEffect(() => {
    document.body.style.overflowY = "hidden"

    return function stop () {
      context.start = true
      document.body.style.overflowY = "auto"
    }
  }, [])

  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    <Container>
    <Layout>
      <Loader />
      <Drag className="drag" id="drag-scroll">Drag or scroll</Drag>
      <Section1 hash={location.hash} className="section-1" />
      <Section2 className="section-2" />
      <Section3 className="section-3" />
    </Layout>
    </Container>
)
}

export default IndexPage


