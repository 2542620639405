import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby';

import Title from '../../title/Title';


import Explore from '../../../utils/explore';

const Subsection = styled.div`
  /* position: relative; */
  width: 100%;
  /* height: 80rem; */
`

const ExplorePos = styled.div`
  position: absolute;
  top: ${props => props.ext};
  left: ${props => props.exl};
  visibility: hidden;
  z-index: 90;

  @media ${props => props.theme.breaks.down('md')} {
    top: ${props => props.extm};
    left: ${props => props.exlm};
  }
`

const Image = styled.div`
  background-image: url(${props => props.src});
  height: 25rem;
  width: 55%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-position: center; */
  background-size: cover;
  visibility: hidden;

  &.few-couches {
    top: 0;
    right: 18%;
    width: 28%;
    height: 100%;
    background-position: right;
    background-size: fill;
    /* z-index: -1; */
  }

  &.creative-team {
    top: 0;
    right: 18%;
    width: 45%;
    height: 46%;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    /* z-index: -1; */
  }


  @media ${props => props.theme.breaks.down('md')} {
    width: 100%;
    bottom: 15%;
    height: 30rem;

    &.few-couches {
      width: 100%;
      bottom: 15%;
      height: 30rem;
      background-size: cover;
      background-position: bottom;
      top: auto;
      right: auto;
    }

    &.creative-team {
      width: 100%;
      bottom: 15%;
      height: 30rem;
      background-size: cover;
      background-position: bottom;
      top: auto;
      right: auto;
    /* z-index: -1; */
  }

  }

  @media ${props => props.theme.breaks.down('smp')} {
    height: 20rem;

    &.few-couches, &.creative-team {
      height: 20rem;
    }
  }
  
`

export default ({image, imageClass, id, className, title, subtitle, color, show, sec, sub, link, ex, exl, ext, exlm, extm}) => {
 
 return (
 <Subsection >
  <Title id={id} className={className} title={title} subtitle={subtitle} color={color} show={show} />
  <ExplorePos exl={exl} ext={ext} exlm={exlm} extm={extm} className={ex}>
    <Link to={link ? link : ''}>
      <Explore sec={sec} sub={sub} />
    </Link>
  </ExplorePos>
  <Image className={imageClass} src={image}></Image>
 </Subsection>
 )
}