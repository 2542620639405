import React from 'react'
import styled from 'styled-components';

import TitleSection from "./TitleSection.js";
import Subsection from './subsection';
import Subsection13 from './subsection-13';
import FormSection from './form-section';

import { theme } from "../../../utils/theme"
import SectionFooter from '../section-footer/SectionFooter';


const SectionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;  
  z-index: 20;

  @media ${props => props.theme.breaks.down('lg')} {
    .section-1-subtitle11 {
      font-size: 10rem;
      top: 45%;
    }

    #dark-bg-14 {
      width: 180px;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {

    .section-1-subtitle11 {
      font-size: 10rem;
      width: 250px;
      left: 30%;
      top: 50%;

      p {
        position: absolute;
        font-size: 7rem;
        transform: rotateZ(-90deg);
        left: -60%;
        top: 30%;
      }
    }

    #dark-bg-14 {
      display: none;
    }
    #section-1-subtitle14 {
      top: 30%;
      left: 10%;
    }

    .section-1-subtitle15  {
      top: 55%;
      p span {
      font-size: 6rem !important;
      }
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {

    #section-1-subtitle14 {
      top: 35%;
      left: 10%;
    }
    .section-1-subtitle15  {
      p span {
      font-size: 4.5rem !important;
      }
    }
  }
`

const Section = styled.section`
  height: 100vh;
  background-color: ${props => props.theme.colors.body};
  overflow-y: hidden;
  overflow-x:hidden;
`

const SubsectionContainer = styled.div`
  position: relative;
  height: 100vh;
`

const FixedFooter = styled.div`
  width: 50rem;
  position: fixed;
  padding-left: 40px;
  bottom: 0;
  left: 0;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`

export default ({className}) => {

  
  return (
    <SectionContainer className="section-3-container">
      <Section className={className}>
        <SubsectionContainer>
          <TitleSection id="section-1-subtitle11" className="section-1-subtitle11" title={<>What we <br /> can do.</>} subtitle="This is" color={theme.colors.primary} sec="1" sub="11" />

          <Subsection imageClass="sec-1-img-12 list-goes" id="section-1-subtitle12" image="https://res.cloudinary.com/celebrand/image/upload/v1618279961/list-keeps-going_ad88oo.jpg" className="section-1-subtitle12" title={<>Just keeps <br /> going.</>} subtitle={<>It's a list of <br /> things that</>} color={theme.colors.primary} sec="1" sub="12" ex="ex-12" ext="75%" exl="65%" link="/services" bg={theme.colors.body} col={theme.colors.dark} opac={true} />

          <Subsection13 imageClass="sec-1-img-13" id="section-1-subtitle13" image="https://res.cloudinary.com/celebrand/image/upload/v1618279958/fist-bump_kq4fbx.png" className="section-1-subtitle13" />

          <Subsection imageClass="sec-1-img-14 easy" id="section-1-subtitle14" image="https://res.cloudinary.com/celebrand/image/upload/v1618279957/easy_fzisfn.jpg" className="section-1-subtitle14" title="Easy." subtitle="It's" color={theme.colors.dark} ex="ex-14" ext="75%" exl="60%" extm="70%" exlm="60%" sec="1" sub="14" link="/how-we-work" col={theme.colors.body} bg={theme.colors.dark} />

          <Subsection imageClass="sec-1-img-15 wrong" id="section-1-subtitle15" image="https://res.cloudinary.com/celebrand/image/upload/v1618926211/whats-wrong_pbv1yj.jpg" className="section-1-subtitle15" title="" subtitle={<span style={{fontSize: '9rem', color: 'inherit', fontWeight: '300'}}>It's listening<br /> to <strong style={{color: 'inherit', fontSize: 'inherit'}}>what's<br /> wrong</strong> with<br /> your business.</span>} color={theme.colors.primary} ex="ex-15" ext="75%" exl="50%" extm="75%" exlm="70%" sec="1" sub="15" link="/pain" col={theme.colors.dark} bg={theme.colors.body} />

          <div id="dark-bg-14"></div>

          <FormSection></FormSection>

          <FixedFooter>
           <SectionFooter />
          </FixedFooter>

        </SubsectionContainer>
      </Section>
    </SectionContainer>
)
}

