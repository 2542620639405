import gsap from 'gsap';
import { theme } from "./theme"



// const hash = {}
export default (e, counter, screenSize) => {

  // const el = e.toElement?.classList?.value


  // const timestamp = hash[el]
  // if (timestamp && (Date.now() - 3000 < timestamp)) return counter;

  // if (el)
  //   hash[el] = Date.now();

  // console.log(screenSize, '.........', counter, e);

  const sectionCn = '.section-1-title';
  const branding = '.section-1-subtitle4';
  const weCan = '.section-1-subtitle11';

  let mobile = screenSize < 960 ? true : false;

  let laptop = screenSize < 1280 && screenSize > 960 ? true : false;

  const tl = gsap.timeline({
    defaults: {duration: .5, ease: "ease.out"}
  })

  const commonCss = {fontSize: "2.5rem", fontWeight: '600', letterSpacing: '.5'}

  let rotate = '';
  let pos = {top: 50, translateY: '50%'}
  let contactDark = {color: theme.colors.dark, borderColor: theme.colors.dark}
  let contactBody = {color: theme.colors.body, borderColor: theme.colors.body}

  if (mobile) {
    rotate = {rotate: 0, top: -30, left: 0}
    pos = {top: 100, left: '10%'}
    contactDark = {color: theme.colors.body, backgroundColor: theme.colors.dark}
    contactBody = {color: theme.colors.dark, backgroundColor: theme.colors.body}
  }

  if (laptop) {
    pos = {top: 20, translateY: '50%'}
  }

  const deltaScroll = 10

  if ((!e.dir && e.deltaY > deltaScroll) || (e.dir && e.dir === "Up")) {

    if (counter === 1) {
    tl.addLabel("start")
    .to(sectionCn, {...pos, opacity: .95, ...commonCss}, "start")
    .to(`${sectionCn} .h3`, { ...commonCss, ...rotate }, "start")

    .to(`.section-1-subtitle1`, {autoAlpha: 1, y: 0, duration: .4}, "start+=.4")
    .fromTo('.sec-1-img-1', {y: 200}, {autoAlpha: .9, y: 0, ease: 'ease.out', duration: .4}, "start+=.6")
    .to(`.ex-1`, {autoAlpha: 1}, "start")
    .to('.bu-sec-1-sub-1', {autoAlpha: 1, rotation: 360, duration: .4}, "start+=.7")
    } 
    
    else if (counter === 4 || counter === 11) { 
      // new section 2 and 3

      let sec = counter === 4 ? 2 : 3;
      let color = counter === 4 ? theme.colors.body : theme.colors.dark;
      let switchUp = counter === 4 ? theme.colors.dark : theme.colors.body;
      let drag = counter === 4 ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)';

      let contactColor = {color: color, borderColor: color}

      if (mobile) {
        contactColor = {color: switchUp, backgroundColor: color}
      }

      tl.addLabel('vanish-sec')
      tl.to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish-sec')
      .to(`.sec-1-img-${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish-sec')
      .to(`.bu-sec-1-sub-${counter - 1}`, {autoAlpha: 0}, 'vanish-sec')
      .to(`.ex-${counter - 1}`, {autoAlpha: 0}, 'vanish-sec')
      .to(`.section-${sec}-container`, {autoAlpha: 1, duration: .1,}, 'vanish-sec')
      .to(`.section-${sec}`, {backgroundColor: color, duration: .1,}, 'vanish-sec')
      .to(`.section-${sec - 1}`, {autoAlpha: 0}, 'vanish-sec')

      tl.addLabel('enter-sec')
      .to('.main-logo .st0', {fill: color, duration: .1}, 'enter-sec-=.2')
      .to('.nav_icon', {backgroundColor: color, duration: .1}, 'enter-sec-=.2')
      .to(`#header a`, {color: color, duration: .1}, 'enter-sec-=.3')
      .to(`.get-touch`, {...contactColor, duration: .1}, 'enter-sec')
      .to(`.footer-icons`, {fill: color, duration: .1}, 'enter-sec')
      .to('.drag', {color: drag, duration: .3}, 'enter-sec')
      .to(`.section-${sec}`, {backgroundColor: switchUp}, 'enter-sec-=.5')
      .fromTo(`.section-1-subtitle${counter}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'enter-sec')
      .fromTo(`.sec-1-img-${counter}`, {x: 300, autoAlpha: 0}, {x: 0, autoAlpha: 1, ease: 'power1'}, 'enter-sec')
    } 

    else if (counter === 5) {
      //section-1-subtitle4
      tl.addLabel('moveUp-5')
      .to(branding, {...pos, opacity: .95, ...commonCss}, "moveUp-5")
      .to(`${branding} .h3`, { ...commonCss, ...rotate }, "moveUp-5")

      .to(`.section-1-subtitle5`, {autoAlpha: 1, y: 0, duration: .4}, "moveUp-5+=.4")
      .fromTo('.sec-1-img-5', {y: 200}, {autoAlpha: 1, y: 0, ease: 'ease.out', duration: .4}, "moveUp-5+=.6")
    }

    else if (counter === 10) {
      tl.addLabel('push-aside')
      tl.to(`.section-1-subtitle${counter - 1}`, {y: -200, autoAlpha: 0}, 'push-aside')
      .to(`.sec-1-img-${counter - 1}`, {y: -200, autoAlpha: 0}, 'push-aside')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0}, 'push-aside')
      .to(`${branding}, ${branding} *`, {color: theme.colors.dark, duration: .2}, 'push-aside+=.4')

      if (!mobile) {
        let left = "60%";
        if (laptop) left = "55%";
        tl.addLabel('pull-in')
        .fromTo(`.section-1-subtitle${counter}`, {y: 200, left: '30%', autoAlpha: 0}, {y: 0, left: left, autoAlpha: 1}, 'pull-in')
        .fromTo(`.sec-1-img-${counter}`, {y: 300, x: 0, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: 'power1'}, 'pull-in')
      } else {
        tl.addLabel('pull-in')
        .fromTo(`.section-1-subtitle${counter}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'pull-in')
        .fromTo(`.sec-1-img-${counter}`, {y: 300, x: 0, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: 'power1'}, 'pull-in')
      }
      
    }

    else if (counter === 12) {
      //section-1-subtitle12
      tl.addLabel('moveUp')
      .to(weCan, {...pos, opacity: .95, ...commonCss}, "moveUp")
      .to(`${weCan} .h3`, { ...commonCss, ...rotate }, "moveUp")

      .to(`.section-1-subtitle12`, {autoAlpha: 1, y: 0, duration: .4}, "moveUp+=.4")
      .fromTo('.sec-1-img-12', {y: 200}, {autoAlpha: 1, y: 0, ease: 'ease.out', duration: .4}, "moveUp+=.6")
      .to(`.ex-${counter}`, {autoAlpha: 1}, 'moveUp')
      .to(`.ex-${counter} .bu-sec-1-sub-${counter}`, {autoAlpha: 1, rotation: 360, duration: .4})
      .to('.drag', {left: '10rem', duration: 0}, 'moveUp')
    }

    else if (counter === 13) { // to section love your business
      tl.addLabel('vanish-12')
      tl.to(`.section-1-subtitle${counter - 1}`, {x: 200, autoAlpha: 0}, 'vanish-12')
      .to(`.sec-1-img-${counter - 1}`, {x: 200, autoAlpha: 0}, 'vanish-12')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0}, 'vanish-12')

      .fromTo(`.section-1-subtitle${counter}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'enter-13')
      .to('.subtitle13-bg', {autoAlpha: 1}, "enter-13")
      .to('.main-logo .st0', {fill: theme.colors.body, duration: .1}, 'enter-13+=.2')
      .to('.nav_icon', {backgroundColor: theme.colors.body, duration: .1}, 'enter-13+=.2')
      .fromTo(`.sec-1-img-${counter}`, {scale: .6, autoAlpha: 0, y: 100}, {autoAlpha: 1, scale: 1, y: 0, ease: 'back.out', duration: .2}, "enter-13+=.6")
      .to('.drag', {autoAlpha: 0, duration: 0}, 'enter-13')
    }

    else if (counter === 14) { // to section easy
      tl.addLabel('vanish-13')
      tl.to(`.section-1-subtitle${counter - 1}`, {x: 200, autoAlpha: 0}, 'vanish-13')
      .to(`.sec-1-img-${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish-13')
      .to('.subtitle13-bg', {autoAlpha: 0}, 'vanish-13')

      tl.addLabel('enter-14')
      .fromTo(`.section-1-subtitle${counter}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'enter-14')
      .fromTo(`.sec-1-img-${counter}`, {x: 300, autoAlpha: 0}, {x: 0, autoAlpha: 1, ease: 'power1'}, 'enter-14')
      .to('.main-logo .st0', {fill: theme.colors.dark, duration: .1}, 'enter-14+=.2')
      .to('.nav_icon', {backgroundColor: theme.colors.dark, duration: .1}, 'enter-14+=.2')
      .to('#dark-bg-14', {autoAlpha: 1}, 'enter-14')
      .to(`#header a`, {color: theme.colors.body, duration: .1}, 'enter-14-=.3')
      .to(`.get-touch`, {...contactBody, duration: .1}, 'enter-14')
      .to(`.ex-${counter}`, {autoAlpha: 1}, 'enter-14-=.1')
      .to(`.ex-${counter} .bu-sec-1-sub-${counter}`, {autoAlpha: 1, rotation: 360, duration: .4}, 'enter-14')
      .to('.drag', {autoAlpha: 1, right: '10rem', left: 'auto', duration: 0}, 'enter-14')
    }


    else if (counter === 15) { // to section what's wrong
      tl.addLabel('vanish-14')
      tl.to(`.section-1-subtitle${counter - 1}`, {x: 200, autoAlpha: 0}, 'vanish-14')
      .to(`.sec-1-img-${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish-14')
      .to([`.ex-${counter - 1} `, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0}, 'vanish-14')

      tl.addLabel('enter-15')
      .fromTo(`.section-1-subtitle${counter}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'enter-15')
      .to(`.sec-1-img-${counter}`,{ autoAlpha: 1, ease: 'power1'}, 'enter-15')
      .to('.section-3', {backgroundColor: theme.colors.dark, duration: .3}, 'enter-15')
      .to('.main-logo .st0', {fill: theme.colors.body, duration: .1}, 'enter-15+=.2')
      .to('.nav_icon', {backgroundColor: theme.colors.body, duration: .1}, 'enter-15+=.2')
      .to(`#header a`, {color: theme.colors.body, duration: .1}, 'enter-15-=.3')
      .to(`.get-touch`, {...contactBody, duration: .1}, 'enter-15')
      .to(`.footer-icons`, {fill: theme.colors.body, duration: .1}, 'enter-15-=.3')
      .to(`.ex-${counter}`, {autoAlpha: 1}, "enter-15-=.1")
      .to(`.ex-${counter} .bu-sec-1-sub-${counter}`, {autoAlpha: 1, rotation: 360, duration: .4}, 'enter-15')
      .to('.drag', {left: '10rem', duration: 0, color: 'rgba(255, 255, 255, .25)'}, 'enter-15')
    }

    else if (counter === 16) { // to final section
      tl.addLabel('vanish-15')
      tl.to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish-15')
      .to(`.sec-1-img-${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish-15')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0}, 'vanish-15')

      tl.addLabel('final')
      .fromTo('.main-form', {autoAlpha: 0, y: -100}, {autoAlpha: 1, y: 0, duration: .5, ease: 'back.out'}, 'final')
    }


    else if (counter < 17) {
      tl.addLabel('vanish')
      tl.to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish')
      .to(`.sec-1-img-${counter - 1}`, {y: 200, autoAlpha: 0}, 'vanish')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0}, 'vanish')

      tl.addLabel('enter')
      .fromTo(`.section-1-subtitle${counter}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'enter')
      .fromTo(`.sec-1-img-${counter}`, {x: 300, autoAlpha: 0}, {x: 0, autoAlpha: 1, ease: 'power1'}, 'enter')
      .to(`.ex-${counter}`, {autoAlpha: 1}, 'enter-=.1')
      .to(`.ex-${counter} .bu-sec-1-sub-${counter}`, {autoAlpha: 1, rotation: 360, duration: .4})
    } 
    
    

    counter = Math.min(counter + 1, 17)
    
  }

  else if ((!e.dir && e.deltaY < -deltaScroll) || (e.dir && e.dir === "Down")) { // if deltaY < 0

    let h1Styles = {top: '50%', translateY: '-50%', fontSize: 120};
    let h3styles = {fontSize: 70, fontWeight: 300}

    if (mobile) {
      h1Styles = {top: '50%', fontSize: '10rem', left: '30%'}
      h3styles = {fontSize: '7rem', fontWeight: 300, rotate: -90, left: '-60%', top: '30%'}
    }

    if (laptop) {
      h1Styles = {top: '45%', translateY: '-50%', fontSize: "10rem"}
    }

    if (counter === 2) {
      tl.addLabel("reverse")
      .to(sectionCn, {...h1Styles}, "reverse+=.2")
      .to(`${sectionCn} .h3`, { ...h3styles}, "reverse+=.2")

      .to(`.section-1-subtitle1`, {autoAlpha: 0, y: 200, duration: .4}, "reverse")
      .to('.sec-1-img-1', {y: 200, autoAlpha: 0, duration: .4}, "reverse")
      .to(['.ex-1', '.bu-sec-1-sub-1'], {autoAlpha: 0, rotation: -360}, 'reverse')
      
    }
    else if (counter === 5 || counter === 12) { // back to previous section

      let sec = counter === 5 ? 2 : 3;
      let color = counter === 5 ? theme.colors.dark : theme.colors.body;
      let switchUp = counter === 5 ? theme.colors.body : theme.colors.dark;
      let drag = counter === 5 ? 'rgba(0, 0, 0, .25)' : 'rgba(255, 255, 255, .25)';

      let contactColor = {color: color, borderColor: color}

      if (mobile) {
        contactColor = {color: switchUp, backgroundColor: color}
      }

      tl.addLabel('exit-sec')
      .to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'exit-sec')
      .to(`.sec-1-img-${counter - 1}`,  {x: 200, autoAlpha: 0}, 'exit-sec')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0, rotation: -360}, 'exit-sec')

      tl.addLabel('reverse-basic-sec')
      .to(`.section-${sec}`, {backgroundColor: switchUp, duration: .3,}, 'reverse-basic-sec-=.5')
      .to('.main-logo .st0', {fill: color, duration: .1}, 'reverse-basic-sec-=.5')
      .to('.nav_icon', {backgroundColor: color, duration: .1}, 'reverse-basic-sec-=.5')
      .to(`#header a`, {color: color, duration: .3}, 'reverse-basic-sec-=.5')
      .to(`.get-touch`, {...contactColor, duration: .3}, 'reverse-basic-sec-=.5')
      .to(`.footer-icons`, {fill: color, duration: .3}, 'reverse-basic-sec-=.5')
      .to(`.section-${sec - 1}`, {autoAlpha: 1, duration: 0}, 'reverse-basic-sec-=.5')
      .to(`.section-${sec}-container`, {autoAlpha: 0, duration: .1,}, 'reverse-basic-sec-=.2')
      .fromTo(`.section-1-subtitle${counter - 2}`, {y: -200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic-sec')
      .fromTo(`.sec-1-img-${counter - 2}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic-sec')
      .to(`.ex-${counter - 2}`, {autoAlpha: 1}, 'reverse-basic-sec-=.1')
      .to(`.ex-${counter - 2} .bu-sec-1-sub-${counter - 2}`, {autoAlpha: 1, rotation: -360}, 'reverse-basic-sec')
      .to('.drag', {color: drag, duration: .1}, 'reverse-basic-sec')
    }

    else if (counter === 6) {
      tl.addLabel("reverseBranding")
      .to(branding, {...h1Styles}, "reverseBranding+=.2")
      .to(`${branding} .h3`, { ...h3styles}, "reverseBranding+=.2")

      .to(`.section-1-subtitle5`, {autoAlpha: 0, y: 200, duration: .4}, "reverseBranding")
      .to('.sec-1-img-5', {y: 200, autoAlpha: 0, duration: .4}, "reverseBranding")
    }

    else if (counter === 11) {
      tl.addLabel('exit-11')
      .to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'exit-11')
      .to(`.sec-1-img-${counter - 1}`,  {x: 200, autoAlpha: 0}, 'exit-11')
      .to(`${branding}, ${branding} .h3`, {color: theme.colors.primary}, 'exit-11')

      tl.addLabel('reverse-basic-11')
      .fromTo(`.section-1-subtitle${counter - 2}`, {y: -200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic-11')
      .fromTo(`.sec-1-img-${counter - 2}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic-11')
      .to(`.ex-${counter - 2}`, {autoAlpha: 1}, 'reverse-basic-11-=.1')
      .to(`.ex-${counter - 2} .bu-sec-1-sub-${counter - 2}`, {autoAlpha: 1, rotation: -360}, 'reverse-basic-11')
    }

    else if (counter === 13) {
      tl.addLabel("reverseWeCan")
      .to(weCan, {...h1Styles}, "reverseWeCan+=.2")
      .to(`${weCan} .h3`, { ...h3styles}, "reverseWeCan+=.2")

      .to(`.section-1-subtitle12`, {autoAlpha: 0, y: 200, duration: .4}, "reverseWeCan-=.1")
      .to('.sec-1-img-12', {y: 200, autoAlpha: 0, duration: .4}, "reverseWeCan-=.1")
      .to(['.ex-12', `.ex-12 .bu-sec-1-sub-12`], {autoAlpha: 0, rotation: -360}, "reverseWeCan-=.1")
      .to('.drag', {right: '10rem', left: 'auto', duration: 0}, 'reverseWeCan')
    }

    else if (counter === 14) { // back from loves your business 
      tl.addLabel('exit-busi')
      .to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'exit-busi')
      .to(`.sec-1-img-${counter - 1}`,  {scale: .6, autoAlpha: 0}, 'exit-busi')
      .to('.subtitle13-bg', {autoAlpha: 0}, 'exit-busi')

      tl.addLabel('reverse-basic-14')
      .fromTo(`.section-1-subtitle${counter - 2}`, {y: -200, x:0, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic-14')
      .fromTo(`.sec-1-img-${counter - 2}`, {y: 200, x: 0, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic-14')
      .to('.main-logo .st0', {fill: theme.colors.dark, duration: .1}, 'reverse-basic-14+=.2')
      .to('.nav_icon', {backgroundColor: theme.colors.dark, duration: .1}, 'reverse-basic-14+=.2')
      .to(`.ex-${counter - 2}`, {autoAlpha: 1}, 'reverse-basic-14-=.1')
      .to(`.ex-${counter - 2} .bu-sec-1-sub-${counter - 2}`, {autoAlpha: 1, rotation: -360}, 'reverse-basic-14')
      .to('.drag', {autoAlpha: 1, left: '10rem', duration: 0}, 'reverse-basic-14')
    }

    else if (counter === 15) {  // back from easy
      tl.addLabel('exit-easy')
      .to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'exit-easy')
      .to(`.sec-1-img-${counter - 1}`,  {x: 300, autoAlpha: 0}, 'exit-easy')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0, rotation: -360}, 'exit-easy')
      .to('#dark-bg-14', {autoAlpha: 0}, 'exit-easy')
      .to(`#header a`, {color: theme.colors.dark, duration: .1}, 'exit-easy')
      .to(`.get-touch`, {...contactDark, duration: .1}, 'exit-easy')
      .to(`.footer-icons`, {fill: theme.colors.dark, duration: .3}, 'exit-easy')

      tl.addLabel('reverse-basic-15')
      .fromTo(`.section-1-subtitle${counter - 2}`, {x: 200, x:0, autoAlpha: 0}, {x: 0, autoAlpha: 1}, 'reverse-basic-15')
      .fromTo(`.sec-1-img-${counter - 2}`, {y: 200, x: 0, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic-15')
      .to('.main-logo .st0', {fill: theme.colors.body, duration: .1}, 'reverse-basic-15+=.2')
      .to('.nav_icon', {backgroundColor: theme.colors.body, duration: .1}, 'reverse-basic-15+=.2')
      .to('.subtitle13-bg', {autoAlpha: 1}, 'reverse-basic-15')
      .to(`.ex-${counter - 2}`, {autoAlpha: 1}, 'reverse-basic-15-=.1')
      .to(`.ex-${counter - 2} .bu-sec-1-sub-${counter - 2}`, {autoAlpha: 1, rotation: -360}, 'reverse-basic-15')
      .to('.drag', {autoAlpha: 0, duration: 0}, 'reverse-basic-15')
    }

    else if (counter === 16) { // back from whats wrong
      tl.addLabel('exit-16')
      .to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'exit-16')
      .to(`.sec-1-img-${counter - 1}`,  {x: 200, autoAlpha: 0}, 'exit-16')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0, rotation: -360}, 'exit-16')
      .to(`#header a`, {color: theme.colors.body, duration: .1}, 'exit-16')
      .to(`.get-touch`, {...contactBody, duration: .1}, 'exit-16')

      tl.addLabel('reverse-16')
      .to('.section-3', {backgroundColor: theme.colors.body, duration: .3}, 'reverse-16')
      .to('#dark-bg-14', {autoAlpha: 1}, 'reverse-16')
      .fromTo(`.section-1-subtitle${counter - 2}`, {x: 200, autoAlpha: 0}, {x: 0, autoAlpha: 1}, 'reverse-16')
      .to('.main-logo .st0', {fill: theme.colors.dark, duration: .1}, 'reverse-16+=.2')
      .to('.nav_icon', {backgroundColor: theme.colors.dark, duration: .1}, 'reverse-16+=.2')
      .fromTo(`.sec-1-img-${counter - 2}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-16')
      .to(`.ex-${counter - 2}`, {autoAlpha: 1}, 'reverse-16-=.1')
      .to(`.ex-${counter - 2} .bu-sec-1-sub-${counter - 2}`, {autoAlpha: 1, rotation: -360}, 'reverse-16')
      .to('.drag', {right: '10rem', left: 'auto', color: 'rgba(0, 0, 0, .25)', duration: 0}, 'reverse-16')
    }

    else if (counter === 17) { // back from final
      tl.addLabel('exit-fianl')
      .to('.main-form', {autoAlpha: 0, duration: .4}, 'exit-final')

      tl.addLabel('reverse-final')
      .fromTo(`.section-1-subtitle${counter - 2}`, {y: -200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-final')
      .fromTo(`.sec-1-img-${counter - 2}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-final')
      .to(`.ex-${counter - 2}`, {autoAlpha: 1}, 'reverse-final-=.1')
      .to(`.ex-${counter - 2} .bu-sec-1-sub-${counter - 2}`, {autoAlpha: 1, rotation: -360}, 'reverse-final')
    }
   
    else {
      tl.addLabel('exit')
      .to(`.section-1-subtitle${counter - 1}`, {y: 200, autoAlpha: 0}, 'exit')
      .to(`.sec-1-img-${counter - 1}`,  {x: 200, autoAlpha: 0}, 'exit')
      .to([`.ex-${counter - 1}`, `.ex-${counter - 1} .bu-sec-1-sub-${counter - 1}`], {autoAlpha: 0, rotation: -360}, 'exit')
      .to(`.ex-${counter - 1}`, {autoAlpha: 0}, 'exit+=.1')

      tl.addLabel('reverse-basic')
      .fromTo(`.section-1-subtitle${counter - 2}`, {y: -200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic')
      .fromTo(`.sec-1-img-${counter - 2}`, {y: 200, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 'reverse-basic')
      .to(`.ex-${counter - 2}`, {autoAlpha: 1}, 'reverse-basic-=.1')
      .to(`.ex-${counter - 2} .bu-sec-1-sub-${counter - 2}`, {autoAlpha: 1, rotation: -360}, 'reverse-basic')
    }
    counter = Math.max(counter - 1, 1)
  }

  return counter;

}