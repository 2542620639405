import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import {throttle} from 'lodash'
import { navigate } from 'gatsby-link';
import {useSwipeable} from 'react-swipeable';


import Title from "../../title/Title";
import Subsection from './subsection';
// import gsap from 'gsap';

import { theme } from "../../../utils/theme"
import scroll from "../../../utils/scroll";
import SectionFooter from '../section-footer/SectionFooter';


const Section = styled.section`
  height: 100vh;
  background-color: ${props => props.theme.colors.body};
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;

  @media ${props => props.theme.breaks.down('lg')} {
    .section-1-title {
      font-size: 10rem;
      top: 45%;
    }
  }

  @media ${props => props.theme.breaks.down('md')} {
    .section-1-title {
      font-size: 10rem;
      word-break: break-all;
      width: 210px;
      left: 30%;
      top: 50%;

      p {
        position: absolute;
        font-size: 7rem;
        transform: rotateZ(-90deg);
        left: -60%;
        top: 30%;
      }
    }
  }
`

const SubsectionContainer = styled.div`
  position: relative;
  height: 100vh;
`

const FixedFooter = styled.div`
  width: 50rem;
  position: fixed;
  padding-left: 40px;
  bottom: 0;
  left: 0;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`

let counter = 1

const slides = ['/#celebrand', '/#couch', '/#couches', '/#creative', '/#branding', '/#can-see', '/#cant-see', '/#stand-out', '/#get-ahead', '/#story', '/#listening', '/#we-can', '/#list', '/#business', '/#easy', '/#whats-wrong', '/#form']

const transition = throttle((e) => {
  // console.log("swiped!!!!!");

  counter = scroll(e, counter, window.innerWidth)

  navigate(slides[counter - 1])
}, 600, {trailing: false})

export default ({className, hash}) => {
  // const [counter, setCounter] = useState(1);
  const {ref, onMouseDown} = useSwipeable({
    onSwipedUp: transition,
    onSwipedDown: transition,
    trackMouse: true,
    preventDefaultTouchmoveEvent: true
  })

  // console.log(ref, onMouseDown);

  useEffect(() => {

    if (hash !== "") {
      counter = slides.indexOf(`/${hash}`) + 1;
    } else {
      counter = 1
    }

  }, [hash])

  useEffect(() => {
    window.addEventListener("wheel", transition)

    ref(document)
    document.addEventListener('mousedown', onMouseDown)

    return () => {
      window.removeEventListener("wheel", transition)
      document.removeEventListener('mousedown', onMouseDown)
      ref()

      // console.log('gone!!!!');
    }
  }, [])

  
  return (
    <Section className={className}>
      <SubsectionContainer>
        <Title id="section-1-title" className="section-1-title" title="Celebrand." subtitle="This is" show />

        <Subsection imageClass="sec-1-img-1" id="section-1-subtitle1" className="section-1-subtitle1" image="https://res.cloudinary.com/celebrand/image/upload/v1618279967/couch2_q7zlvb.jpg" title="Couch." subtitle="It's a" color={theme.colors.dark} sec="1" sub="1" link="/couches" ex="ex-1" ext="46%" exl="40px" extm="35%" exlm="75%" />

        <Subsection imageClass="sec-1-img-2 few-couches" id="section-1-subtitle2" image="https://res.cloudinary.com/celebrand/image/upload/v1618279962/few-couches_spdflm.jpg" className="section-1-subtitle2" title="Couches." subtitle="It's a few" color={theme.colors.dark} />

        <Subsection imageClass="sec-1-img-3 creative-team" id="section-1-subtitle3" image="https://res.cloudinary.com/celebrand/image/upload/v1618279963/creative-team_pylauz.jpg" className="section-1-subtitle3" title={`Creative \n Team.`} subtitle="It's a" color={theme.colors.dark} ex="ex-3" ext="75%" exl="75%" extm="35%" exlm="75%"  sec="1" sub="3" link="/team" />

        <FixedFooter>
          <SectionFooter />
        </FixedFooter>

      </SubsectionContainer>
    </Section>
)
}

