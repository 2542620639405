import React from 'react'
import styled from 'styled-components'

import Title from '../../title/Title';

import Explore from '../../../utils/explore';

const Subsection = styled.div`
  /* position: relative; */
  width: 100%;
  /* height: 80rem; */
`


export default ({id, className, title, subtitle, color, show, sec, sub}) => {
 
 return (
 <Subsection >
  <Title id={id} className={className} title={title} subtitle={subtitle} color={color} show={show} />
  <Explore sec={sec} sub={sub} />
 </Subsection>
 )
}