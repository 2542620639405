import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import Title from '../../title/Title';

import Explore from '../../../utils/explore';

const Subsection = styled.div`
  /* position: relative; */
  width: 100%;
  /* height: 80rem; */
`

const ExplorePos = styled.div`
  position: absolute;
  top: ${props => props.ext};
  left: ${props => props.exl};
  z-index: 90;

  @media ${props => props.theme.breaks.down('md')} {
    top: ${props => props.extm};
    left: ${props => props.exlm};
  }
`

const Image = styled.div`
  background-image: url(${props => props.src});
  overflow: hidden;
  position: absolute;
  visibility: hidden;

  &.can-see {
    top: 0;
    right: 25%;
    width: 25%;
    height: 46%;
    background-size: cover;
    background-position: center;
  }

  &.stand-out {
    top: 0;
    right: 10%;
    width: 55%;
    height: 60%;
    background-size: cover;
    background-position: center;

    @media ${props => props.theme.breaks.down('md')} {
      right: 0;
      top: auto;
      bottom: 0;
      width: 80%;
      height: 80%;
      background-size: cover;
      background-position: left;
    }
  }

  &.get-ahead {
    bottom: 0;
    right: 0;
    height: 50%;
    width: 55%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;

    @media ${props => props.theme.breaks.down('md')} {
      background-position: center;
      width: 100%;
      height: 45%;
    }
  }

  &.your-story {
    top: 0;
    right: 10%;
    width: 55%;
    height: 55%;
    background-size: cover;
    background-position: center;

    @media ${props => props.theme.breaks.down('md')} {
      /* right: 0;
      background-position: center;
      height: 85%;
      width: 15%;
      bottom: 0;
      top: auto; */
      width: 0;
    }
  }

  &.listening {
    top: 0;
    left: 16%;
    width: 35%;
    height: 100%;
    background-size: cover;
    background-position: center;

    @media ${props => props.theme.breaks.down('md')} {
      width: 100%;
      height: 40%;
      top: auto;
      bottom: 0;
      left: auto;
    }
  }

`

const Video = styled.div`
  overflow: hidden;
  position: absolute;
  visibility: hidden;

  &.can-see {
    top: 0;
    right: 20%;
    width: 30%;
    height: 48%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
  @media ${props => props.theme.breaks.down('md')} {
    &.can-see {
      top: auto;
      bottom: 0;
      width: 100%;
      right: auto;
    }
  }
`


export default ({image, video, videoOg, imageClass, id, className, title, subtitle, color, show, sec, sub, light, br, link, ex, exl, ext, bg, col, exlm, extm}) => {
 
 return (
 <Subsection >
  <Title id={id} light={light} br={br} className={className} title={title} subtitle={subtitle} color={color} show={show} />
  <ExplorePos exl={exl} ext={ext} exlm={exlm} extm={extm} className={ex}>
    <Link to={link ? link : ''}>
      <Explore bg={bg} col={col} sec={sec} sub={sub} />
    </Link>
  </ExplorePos>
  {image ? <Image className={imageClass} src={image}></Image> : null}
  {video ? <Video className={imageClass}><video autoPlay loop muted playsInline>
    <source src={video} type="video/mp4" />
    <source src={videoOg} type="video/ogg" />
  </video></Video> : null}
 </Subsection>
 )
}