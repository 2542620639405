import React from 'react'
import styled from 'styled-components';
import {Link} from 'gatsby';

import Title from '../../title/Title';

import Explore from '../../../utils/explore';

const Subsection = styled.div`
  /* position: relative; */
  width: 100%;
  /* height: 80rem; */

  
`
const ExplorePos = styled.div`
  position: absolute;
  top: ${props => props.ext};
  left: ${props => props.exl};
  visibility: hidden;
  z-index: 90;

  @media ${props => props.theme.breaks.down('md')} {
    top: ${props => props.extm};
    left: ${props => props.exlm};
  }
`

const Image = styled.div`
  background-image: ${props => props.opac ? `linear-gradient(rgba(0,0,0, .5), rgba(0,0,0, .5)), url(${props.src})` : `url(${props.src})`};
  overflow: hidden;
  position: absolute;
  visibility: hidden;

  &.list-goes {
    right: 0;
    top: 0;
    width: 60%;
    height: 100vh;
    background-position: center;

    @media ${props => props.theme.breaks.down('md')} {
      bottom: 0;
      top: auto;
      width: 100%;
      height: 70%;
      background-size: cover;
    }
  }

  &.easy {
    left: 20%;
    top: 40%;
    max-width: 500px;
    width: 30%;
    height: 350px;
    background-position: center;
    background-size: cover;

    @media ${props => props.theme.breaks.down('md')} {
      width: 100%;
      max-width: 100%;
      left: auto;
      height: 30rem;
      bottom: 15%;
    }

    @media ${props => props.theme.breaks.down('smp')} {
      height: 20rem;
      bottom: auto;
      top: 48%;
    }
  }

  &.wrong {
    right: 0;
    width: 60%;
    height: 100%;
    top: 0;

    @media ${props => props.theme.breaks.down('md')} {
      display: none;
    }
  }
  
`

export default ({image, imageClass, id, className, title, subtitle, color, show, sec, sub, light, br, opac, link, ex, exl, ext, bg, col, exlm, extm}) => {
 
 return (
 <Subsection >
  <Title id={id} light={light} br={br} className={className} title={title} subtitle={subtitle} color={color} show={show} />
  <ExplorePos exl={exl} ext={ext} exlm={exlm} extm={extm} className={ex}>
    <Link to={link ? link : ''}>
      <Explore bg={bg} col={col} sec={sec} sub={sub} />
    </Link>
  </ExplorePos>
  {image ? <Image className={imageClass} src={image} opac={opac}></Image> : null}
 </Subsection>
 )
}