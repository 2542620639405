import React, {useState} from 'react';
import styled from 'styled-components';
import { theme } from "../../../utils/theme";
import { navigate } from 'gatsby-link';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { encode } from '../../../utils/util';



const Container = styled.div`
  /* height: 100vh; */
  /* height: 100vh; */
  width: 100%;
  background-color: transparent;
  /* overflow-y: scroll; */

  .main-form {
    visibility: hidden;
  }
`

const Title = styled.h1`
  font-weight: 300;
  font-size: 7rem;
  color: ${props => props.theme.colors.primary};
  width: 450px;
  margin: -50px auto 70px auto;
  /* margin-bottom: 50px; */
  text-align: left;

  .yellow-bold {
    font-size: inherit;
    color: ${props => props.theme.colors.body};
  }

  @media ${props => props.theme.breaks.down('lg')} {
    font-size: 5rem;
    margin-top: -20px;
    width: 300px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 120px;
    margin-bottom: 30px;
    font-size: 2rem;
    text-align:left;
    width: 90%;
  }

  @media ${props => props.theme.breaks.down('smp')} {
    margin-top: 70px;
  }
`

const MainSection = styled.div`
  /* height: 100vh; */
  text-align: center;

  .bottom-form {
    margin-top: 15px;
  }
`

const Form = styled.form`
  width: 60%;
  margin: 0 auto;
  color: ${props => props.theme.colors.body};

  & * {
  color: ${props => props.theme.colors.body};

  }

  .MuiFormLabel-root {
  color: ${props => props.theme.colors.body};
  }

  .MuiInputBase-input {
    border-bottom: 2px solid ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.primary};
  }

  .MuiInputBase-multiline.MuiInput-underline:before {
    bottom: 7px;
  }

  .MuiInputBase-multiline.Mui-focused:after {
    bottom: 7px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;

    .MuiGrid-spacing-xs-5 > .MuiGrid-item {
      padding: 6px 20px;
    }
  }
`

const Button = styled.button`
  display: block;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 96%;
  border: none;
  padding: 15px 0;
  margin: 0 auto;
  margin-top: 80px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 40px;
    width: 90%;
  }

  @media ${props => props.theme.breaks.down('smp')} {
    margin-top: 30px;
    padding: 10px 0;
    
  }
`


const MainFormPage = ({ data }) => {

  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    
      <Container>
        
        <MainSection className="main-form">
          {/* <Top> */}
            <Title>It’s finding
            out <span className="yellow-bold"> how we
            can help.</span>
            </Title>
           
          {/* </Top> */}
          <Form
            name="main-form"
            method="post"
            action="/submitted"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="main-form" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>

            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>

                <TextField fullWidth name="name" label="Your name" placeholder="Your name" color="secondary" value={state.name} onChange={handleChange} required></TextField>

              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="company" label="Your company" placeholder="Your company" color="secondary" value={state.company} onChange={handleChange} required></TextField>
                
              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="email" label="Your email" placeholder="Your email" type="email" color="secondary" value={state.email} onChange={handleChange} required></TextField>
                
              </Grid>
{/*             
              <Grid item xs={6} md={4}>
                
                <TextField fullWidth name="find" label="How did you find out about us?" placeholder="How did you find out about us?" value={state.find} onChange={handleChange} color="secondary"></TextField>
                
              </Grid>
              <Grid item xs={6} md={4}>
                
                <TextField fullWidth name="budget" label="Budget for the project?" placeholder="Budget for the project?" value={state.budget} onChange={handleChange} color="secondary"></TextField>
                
              </Grid>
              <Grid item xs={6} md={4}>
                
                <TextField fullWidth name="time" label="Time frame for the project?" placeholder="Time frame for the project?" type="email" value={state.time} onChange={handleChange} color="secondary"></TextField>
                
              </Grid> */}
            
              <Grid item xs={12} md={4}>
                <TextField multiline rows={4} fullWidth name="about" label="Tell us about the project" value={state.about} onChange={handleChange} placeholder="Tell us about the project" color="secondary"></TextField>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Button>Submit</Button>
            </Grid>
          </Form>
          
        </MainSection>
        
      </Container>
    
)
}

export default MainFormPage;