import React from "react";

import styled from "styled-components";

const LoadingBar = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: ${props => props.theme.colors.dark};
`

const Counter = styled.div`
  position: absolute;
  right: -10rem;
  top: -10rem;
  transform: rotateZ(90deg);
  font-size: 6rem;
`

export default () => {
  return (
  <LoadingBar className="loader">
    <Counter id="counter">0</Counter>
  </LoadingBar>
  )
}